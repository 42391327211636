<template>
  <div>
    <app-bar
      title="Kontak"
      :previousLink="{
        name: 'Profil'
      }"
      :noBackButtonOnDesktop="true"
      class="sub"
    />
    <Form
      @submit="update"
      v-slot="{ errors }"
      class="mt-3 mt-md-2 mb-4 pb-5 pb-md-0 mb-md-0">

      <section class="mb-3">
        <label>Email</label>
        <div class="input-group">
          <Field
            type="text"
            v-model="form.email_user"
            name="email_user"
            class="form-control"
            :class="{ 'is-invalid w-100': errors.email_user }"
            readonly
            placeholder="Email anda" />
          <ErrorMessage name="email_user" class="invalid-feedback" />
        </div>
      </section>

      <section class="mb-3">
        <label>No. Telp</label>
        <div class="input-group">
          <VueTelInput
            :value="form.pas_hp"
            v-model="form.pas_hp"
            name="pas_hp"
            rules="required"
            mode="international"
            class="form-control"
            :class="{ 'is-invalid': errors.pas_hp }"
            :inputOptions="{
              'placeholder': '8xxx xxxx xxxx'
            }"
            :validCharactersOnly="true"
            @input="handleInputHP"
          />
          <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_hp || errorMessage }">
            <div class="show-password" type="button" @click="form.pas_hp = ''" :class="{'opacity-0':!form.pas_hp}">
              <i class="ri-close-circle-fill fs-24"></i>
            </div>
          </span>
          <span v-if="!errors.pas_hp" class="invalid-feedback">{{ errorMessage }}</span>
          <ErrorMessage name="pas_hp" class="invalid-feedback" />
        </div>
      </section>

      <div v-if="!sameAsWa">
        <label>WhatsApp</label>
        <div class="input-group mb-3">
          <VueTelInput
            :value="form.pas_wa"
            v-model="form.pas_wa"
            name="pas_wa"
            rules="required"
            mode="international"
            class="form-control"
            :class="{ 'is-invalid': errors.pas_wa }"
            :inputOptions="{
              'placeholder': '8xxx xxxx xxxx'
            }"
            :validCharactersOnly="true"
            @change="handleWa"
            @input="handleInputWA"
          />
          <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.pas_wa || errorMessage }">
            <div class="show-password" type="button" @click="form.pas_wa = ''" :class="{'opacity-0':!form.pas_wa}">
              <i class="ri-close-circle-fill fs-24"></i>
            </div>
          </span>
          <span v-if="!errors.pas_wa" class="invalid-feedback">{{ errorMessage }}</span>
          <ErrorMessage name="pas_wa" class="invalid-feedback" />
        </div>
      </div>

      <div class="input-group mb-2">
        <div class="form-check d-flex align-items-center">
          <input
            type="checkbox"
            class="form-check-input"
            id="exampleCheck1"
            v-model="sameAsWa"
            @change="handleWa"
          >
          <label class="form-label pt-1 ms-2 mb-0 fw-400 fs-12 lh-22 color-neutral-700" for="exampleCheck1">No. Telp sama dengan WhatsApp</label>
        </div>
      </div>

      <!-- <section class="mb-3">
        <label class="form-label text-body fst-normal fw-bold fs-14 lh-19">
          No HP
        </label>
        <Field
          type="pas_hp"
          v-model="form.pas_hp"
          name="pas_hp"
          rules="required"
          class="form-control text-placeholder fst-normal fw-600 fs-12 lh-16 p-3 border-0"
          :class="{ 'is-invalid w-100': errors.pas_hp }"
          placeholder="No HP" />
        <ErrorMessage name="pas_hp" class="invalid-feedback" />
      </section> -->

      <!-- <section class="mb-3">
        <label class="form-label text-body fst-normal fw-bold fs-14 lh-19">
          No WA
        </label>
        <Field
          type="pas_wa"
          v-model="form.pas_wa"
          name="pas_wa"
          rules="required"
          class="form-control text-placeholder fst-normal fw-600 fs-12 lh-16 p-3 border-0"
          :class="{ 'is-invalid w-100': errors.pas_wa }"
          placeholder="No WA" />
        <ErrorMessage name="pas_wa" class="invalid-feedback" />
      </section> -->

      <div class="row">
        <div class="col-md-4">
          <button
            class="btn btn-primary w-100 pt-2 pb-2"
            type="submit"
            :disabled="loading.updatePersonalData">
            {{ loading.updatePersonalData ? 'Memperbarui' : 'Simpan' }}
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { user } from '@/core/auth'
import { showNotif } from '@/core/utility'
import { updatePersonalData, loading } from '@/core/profile'
import { Field, Form, ErrorMessage } from 'vee-validate'
import { nextTick, reactive, ref, onMounted } from 'vue'
import { title } from '@/core/page'
import { useRouter } from 'vue-router'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  components: { AppBar, Field, Form, ErrorMessage, VueTelInput },
  data () {
    return {
      bindProps: {
        mode: 'international',
        defaultCountry: 'ID',
        onlyCountries: ['ID'],
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: 'off',
        required: true
      }
    }
  },
  setup () {
    nextTick(() => {
      title.value = 'Update Data Diri'
    })

    const errorMessage = ref('')
    const sameAsWa = ref(false)
    const router = useRouter()
    const form = reactive({
      pas_id: user.value.pas_id,
      email_user: user.value.email_user,
      pas_noktp: user.value.pas_noktp,
      pas_tgllahir: user.value.pas_tgllahir,
      pas_kota: user.value.pas_kota,
      pas_alamat: user.value.pas_alamat,
      pas_jenkelamin: user.value.pas_jenkelamin,
      pas_hp: user.value.pas_hp,
      pas_wa: user.value.pas_wa
    })

    const handleWa = () => {
      if (sameAsWa.value) {
        form.pas_wa = form.pas_hp
      }
    }

    const update = async () => {
      const result = await updatePersonalData(form, { foto: null, ktp: null })

      if (result === true) {
        showNotif({
          status: 'success',
          message: 'Data kontak berhasil diperbarui'
        })
        router.push({ name: 'Profil' })
      }
    }

    const handleInputHP = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        form.pas_hp = phoneObject.formatted
      }
    }

    const handleInputWA = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        form.pas_wa = phoneObject.formatted
      }
    }

    onMounted(() => {
      // cek nomor hp sama dengan nomor wa
      if (user.value.pas_hp === user.value.pas_wa) {
        sameAsWa.value = true
      }
    })

    return {
      user,
      form,
      errors: {},
      updatePersonalData,
      loading,
      update,
      errorMessage,
      handleWa,
      sameAsWa,
      handleInputHP,
      handleInputWA
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.vue-tel-input {
  border: none;
}
</style>
